import appConfig from './config.json'
import czFlag from "./assets/flags/cz.svg";
import enFlag from "./assets/flags/gb.svg";
import huFlag from "./assets/flags/hu.svg";
import deFlag from "./assets/flags/de.svg";


let apiURL = '';
let notificationApiURL = '';
let defaultApiUrl = appConfig.API_URL;
let defaultNotificationApiUrl = appConfig.NOTIFICATION_API_URL;
let newApiUrl = appConfig.API_URL_NEW;

let enableLog = appConfig.log;

export function log(text) {
  if (appConfig.log) {
    console.log(text);
  }
}


export function getLanguageFlagFile(lang) {
  switch (lang) {
    case "cz":
      return czFlag;
    case "en":
      return enFlag
    case "hu":
      return huFlag
    case "de":
      return deFlag
    default:
      return czFlag
  }
}

// TODO - replace with Set
export const uniqueArray = (objects, uniqueBy, keepFirst = true) => {
  return Array.from(
    objects.reduce((map, e) => {
      const key = uniqueBy.map(key => [e[key], typeof e[key]]).flat().join('-');
      if (keepFirst && map.has(key)) return map;
      return map.set(key, e);
    }, new Map()).values()
  ).sort((a, b) => {
    const aValue = a[uniqueBy];
    const bValue = b[uniqueBy];
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue); // Sort alphabetically
    } else {
      return aValue - bValue; // Sort numerically
    }
  });
}



export function getApiURL(type) {
  if (apiURL === '') {
    if (type === 'new') {
      return newApiUrl;
    }
    else if (type === "di") {
      return appConfig.DIAPI_URL;
    }
    return defaultApiUrl;
  } else {
    return apiURL;
  }
}


export function getNotificationApiURL() {
  if (notificationApiURL === '') {
    return defaultNotificationApiUrl;
  } else {
    return notificationApiURL;
  }
}


export function setDevApiURL() {
  apiURL = appConfig.API_URL_DEV;
}

export function setProductionApiURL() {
  apiURL = appConfig.API_URL;
}

export function setLog(log) {
  enableLog = log;
}

export function compareObjs(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2);
}


export function getNumber(n) {
  if (n !== null && n !== 0) {
    n = n.replace(',', '.');
    if (!isNaN(parseFloat(n)) && isFinite(n)) {
      return n;
    } else {
      return 1;
    }
  } return 0;
}

export const removeScript = (scriptToremove) => {
  let allsuspects = document.getElementsByTagName("script");
  for (let i = allsuspects.length; i >= 0; i--) {
    if (allsuspects[i] && allsuspects[i].getAttribute("src") !== null
      && allsuspects[i].getAttribute("src").indexOf(`${scriptToremove}`) !== -1) {
      allsuspects[i].parentNode.removeChild(allsuspects[i])
    }
  }
}


export const appendScript = (scriptToAppend) => {
  const script = document.createElement("script");
  script.src = scriptToAppend;
  script.async = true;
  document.body.appendChild(script);
}

export function degToRad(degrees) {
  var pi = Math.PI;
  return degrees * (pi / 180);
}


function generateKey(groupBySeconds, timestamp, offset) {
  var keyDiff = (timestamp % groupBySeconds);
  return [(timestamp - keyDiff) + offset, keyDiff];
}


function generateSlots(dateStart, dateEnd, groupBy) {
  var startKeyGen = generateKey(groupBy, dateStart, 0);
  var startHeadKey = startKeyGen[0];
  var keyStep = startKeyGen[1];

  var endKeyGen = generateKey(groupBy, dateEnd, 0);
  var endTailKey = endKeyGen[0];

  var endKey = startHeadKey;

  var shift = dateStart - startHeadKey;

  var grid = {};
  while (endKey <= endTailKey) {
    grid[endKey + shift] = [];
    endKey += groupBy;
  }

  return { "grid": grid, "offset": shift };

}


export function groupBy(data, startDate, endDate, tsKey, groupDurationSec, pushTo) {
  pushTo = typeof pushTo !== 'undefined' ? pushTo : 'bottom';
  var slotsAndOffsets = generateSlots(startDate, endDate, groupDurationSec);
  var slots = slotsAndOffsets.grid;
  var offset = slotsAndOffsets.offset;


  data.forEach(item => {
    var key = generateKey(groupDurationSec, data[item][tsKey] - offset, offset)[0];
    if (slots[key] != undefined) {
      if (pushTo == 'top') {
        slots[key].unshift(data[item]);
      }
      else {
        slots[key].push(data[item]);
      }
    }
  });

  return (slots);
}

